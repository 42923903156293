import Vue from 'vue';
import Router from 'vue-router';
import EventBus from '@/common/EventBus';
import SentryService from '@/services/SentryService';

import AppContainer from '@/components/containers/AppContainer.vue';
import AuthContainer from '@/components/containers/AuthContainer.vue';
import config from './config';

import store from '@/store';
import Util from './utils/Util';

Vue.use(Router);

const envConfig: any = config;

const routes = [
  {
    path: '/',
    name: 'root',
    component: AppContainer,
    redirect: envConfig.urls.rootRedirect.includes('/landing') || envConfig.urls.rootRedirect.includes('/portal') ? (to) => {
      window.location.href = `https://${window.location.host}${__webpack_public_path__}${envConfig.urls.rootRedirect}`;
    } : envConfig.urls.rootRedirect,
    children: [
      {
        path: 'continuation',
        name: 'continuation',
        component: () => import(/* webpackChunkName: "continuation" */'@/views/public/Continuation.vue')
      },
      {
        path: 'renewal',
        name: 'renewal',
        component: () => import(/* webpackChunkName: "renewal" */'@/views/public/Renewal.vue')
      },
      {
        path: 'savedProposal',
        name: 'savedProposal',
        component: () => import(/* webpackChunkName: "savedProposal" */'@/views/public/SavedProposal.vue')
      },
      {
        path: 'callback',
        name: 'authCallback',
        component: () => import(/* webpackChunkName: "callback" */'@/views/public/AuthCallback.vue')
      },
      {
        path: 'auth/:targetSegment',
        name: 'auth',
        component: AuthContainer,
        children: [
          {
            path: 'error',
            name: 'error',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/Error.vue'),
            beforeEnter: (to, from, next) => {
              SentryService.logException({type: 'APP', error: `User redirected to error screen from ${from.path}`, level: 'fatal'});
              next();
            }
          },
          {
            path: 'renewal',
            name: 'renewal-request',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/RenewalRequest.vue')
          },
          {
            path: 'landing',
            name: 'landing',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/LandingCatalogue.vue')
          },
          {
            path: 'paymentProviderRedirect',
            name: 'paymentProviderRedirect',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/PaymentProviderRedirect.vue')
          },
          {
            path: 'portal',
            name: 'portal',
            component: () => import(/* webpackChunkName: "portal" */'@/views/auth/Portal/Index.vue'),
            children: [
              {
                path: 'review',
                name: 'review',
                component: () => import(/* webpackChunkName: "portal" */'@/views/auth/Portal/Index.vue')
              }
            ]
          },
          {
            path: 'confirmation',
            name: 'confirmation',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/Confirmation.vue')
          },
          {
            path: 'download-forms',
            name: 'download-forms',
            component: () => import(/* webpackChunkName: "index" */'@/views/auth/DownloadForms.vue')
          }
        ]
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */'@/views/public/Maintenance.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "index" */'@/views/public/GenericError.vue'),
    beforeEnter: (to, from, next) => {
      if (to.path === '/error') {
        SentryService.logException({type: 'APP', error: `User redirected to error screen from ${from.path}`, level: 'fatal'});
      }
      next();
    }
  }
];

if (envConfig.urls.rootRedirect === 'comingsoon') {
  const path: any = {
    path: '/comingsoon',
    component: () => import(/* webpackChunkName: "index" */'@/views/public/ComingSoon.vue')
  };
  routes.push(path);
}

const router = new Router({
  mode: 'history',
  base: __webpack_public_path__,
  routes,
});

function patchRouterMethod(r, methodName) {
  r['old' + methodName] = r[methodName];
  r[methodName] = async (location) => {
    return r['old' + methodName](location).catch((error) => {
      if (Router.isNavigationFailure(error)) {
        return error;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, 'push');
patchRouterMethod(router, 'replace');

router.onError((error) => {
  console.error('Router error:', error);
  EventBus.$emit(`redirect-error`);
});


router.beforeEach((to, from, next) => {
  setTimeout(() => {
    Util.gaLogCustomEvent(window, 'page_load', 'virtual_pageview', {
      page_location: to.fullPath
    });
  }, 5000);
  // when dirty is true, means there are cached quote configuration, need to clear before back to landing page.
  // when skipConfirm is 'true', skip the confirmation dialog.
  if (from.name !== null && to.name === 'landing' && store.getters['app/getValue']('dirty')) {
    EventBus.$emit(`want-back`, to.params.skipConfirm);
    next(false);
  } else {
    if (to.name === 'landing' && to.query.products) {
      const newQuery = { ...to.query };
      delete newQuery.products;
      next({ path: to.path, query: newQuery });
      return;
    }
    if (to.name !== 'landing' && from.query.products && !to.query.products) {
      to.query.products = from.query.products;
      next({ path: to.path, query: to.query });
      return;
    }
    if (!to.query.subSegment && sessionStorage.subSegment) {
      to.query.subSegment = sessionStorage.subSegment;
      next({ path: to.path, query: to.query });
      return;
    }
    if (from.query.language && !to.query.language) {
      to.query.language = from.query.language;
      next({ path: to.path, query: to.query });
      return;
    }
    if (from.query.profile && !to.query.profile) {
      to.query.profile = from.query.profile;
      next({ path: to.path, query: to.query });
      return;
    }
    if (from.query.line && !to.query.line) {
      to.query.line = from.query.line;
      next({ path: to.path, query: to.query });
      return;
    }
    if (from.query.areaOfService && !to.query.areaOfService) {
      to.query.areaOfService = from.query.areaOfService;
      next({ path: to.path, query: to.query });
      return;
    }
    if (to.query.profile) {
      store.dispatch('app/setProfile', to.query.profile);
    }
    if (to.query.affiliatecode) {
      store.dispatch('app/setAffiliateCode', to.query.affiliatecode);
    }
    if (from.name === 'confirmation' && to.name === 'portal' ) {
      if (to.query.products) {
        delete to.query.products;
      }
      next({name: 'landing' });
    } else {
      next();
    }
  }
});

export default router;
