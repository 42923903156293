import _ from 'lodash';
import moment from 'moment';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import WorkflowService from '@/services/WorkflowService';
import GAUtil from './GAUtil';
import store from '@/store';
import conf from '@/config';
import SentryService from '@/services/SentryService';

export default class Util {
  public static MOBILE_ID_SUFFIX = 'phone';
  public static APPLICANT_MOBILE_ID = `applicant.${Util.MOBILE_ID_SUFFIX}`;
  public static COMPONENT_PHONE_TYPE_ID = 'PHONE_NUMBER';
  public static APPLICANT_EMAIL_ID = 'applicant.email';
  public static APPLICANT_PROVINCE_ID = 'applicant.province';
  public static APPLICANT_FIRSTNAME_ID = 'applicant.firstname';
  public static POLICY_HOLDER_BUSINESS_NAME = 'policyholder.businessname';
  public static ASSOCIATION_ID = 'association';
  public static DECLARATION_FACT_IDS = 'DECLARATION_FACT_IDS';
  public static COINSURANCE_FACT_ID = 'coinsurance.statement';
  public static COMPONENT_START_DATE_ID = 'COMPONENT:base.base.start';
  public static COMPONENT_END_DATE_ID = 'COMPONENT:base.base.end';
  public static COMPONENT_FIRST_WORKER_FULLNAME_ID = 'COMPONENT:base.INSURED:worker:1.fullname';
  public static COMPONENT_RENEWAL_ID = 'renewal.renewal';
  public static WARNINGS_FACT_ID = 'warnings.text';
  public static ELIGIBILITY_FACT_ID = 'membership.eligibility';
  public static DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
  public static INVALID_START_DATE_CODE = 2;
  public static INVALID_TERM_LENGTH_CODE = 3;
  public static INVALID_START_BEFORE_END_CODE = 4;
  public static PARDOT_TIMESTAMP_FORMAT = 'YYYYMMDD hh:mm:ss';
  public static SHIFT_QUOTE_TYPE_RENEWAL = 'RENEWAL';
  public static SHIFT_QUOTE_TYPE_NEW = 'NEW';
  public static SEPARATE_COVERAGE_PERIOD = 'SEPARATE_COVERAGE_PERIOD';

  public static MOBILE_BREAKPOINT = '$vuetify.breakpoint.xsOnly';
  public static RE_RENDER_DEBOUNCE = 200;
  public static RE_RENDER_DEBOUNCE_OPTIONS = { leading: false, trailing: true };

  public static PARDOT_ACTIONS = {
    REPORT_BASIC_INFO: 'reportBasicInfo',
    REPORT_CONTACT_US: 'reportContactUS',
    REPORT_RENEWAL_ACCESSED: 'reportRenewalAccessed',
    REPORT_OFFLINE_PRODUCTS: 'reportOfflineProducts',
    REPORT_CONFIGURATION: 'reportConfiguration',
    REPORT_DETAILS: 'reportDetails',
    REPORT_RENEWAL_PAID: 'reportRenewalPaid',
    REPORT_CONTINUATION_PAID: 'reportContinuationPaid',
    REPORT_PAYMENT_ISSUE: 'reportPaymentIssue',
    REPORT_CONTINUATION_ACCESSED: 'reportContinuationAccessed',
    REPORT_SUBMISSION: 'reportSubmission',
    REPORT_RENEWAL_USED: 'reportRenewalUsed',
    REPORT_VERIFICATION: 'reportVerification',
    REPORT_RENEWAL: 'reportRenewal',
    PLACEHOLDER_METHOD: (placeholder: any) => new Promise((resolve, reject) => reject('no pardot'))
  };

  public static GA_PAYMENT_STATUSES_TYPE = {
    SUCCESS: 'SUCCESS',
    SKIPPED: 'SKIPPED'
  };

  public static GA_PAYMENT_STATUSES_RESULT = {
    NOTHING_TO_PAY: 'NOTHING_TO_PAY',
    PAID: 'PAID',
    SKIPPED: 'SKIPPED'
  };

  public static GA_PAYMENT_STATUSES = {
    SUCCESS: {
      NOTHING_TO_PAY: 'success/nothing to pay',
      PAID: 'success/paid'
    },
    SKIPPED: {
      SKIPPED: 'skipped'
    }
  };

  public static convertToEsCompatible(value: any) {
    const result: any = {};
    _.each(value, (v, k) => {
      if (v && typeof v === 'object' && v.toArray) {
        const arr = v.toArray();
        result[k] = _.map(arr, (a) => this.convertToEsCompatible(a));
      } else {
        result[k] = v;
      }
    });
    return result;
  }

  public static stringToHtml(text: string) {
    return text && text.replace(/\n/g, '<br>');
  }

  public static findAncestor(el, sel) {
    do {
      el = el && el.parentElement;
    } while (el && !((el.matches || el.matchesSelector).call(el, sel)));
    return el;
  }

  public static addDate(
    from: Date,
    duration: any,
    addType: string = 'days', // days, months, years
  ) {
    const to = moment(from)
      .add(duration, addType)
      .toISOString();
    return new Date(new Date(to).setUTCHours(0, 0, 0, 0));
  }

  public static isSeparateCoveragePeriod(productCode: string) {
    return _.includes(_.get(store.state.app.config.shiftMappings, Util.SEPARATE_COVERAGE_PERIOD), productCode);
  }

  public static isEmptyString(str) {
    return _.isNil(str) || str.match(/^\s*$/) !== null;
  }

  public static findAsset(assets: any, data: any, field: string) {
    const id = _.get(data, 'fields[' + field + '].sys.id');
    const asset = id && assets.find((a) => a.sys.id === id);
    if (asset) {
      return `https:${asset.fields.file.url}`;
    }
    return '';
  }

  public static redirect(router, app: any, routeName: string) {
    if (app === null) {
      const query: any = router.currentRoute.query;
      const params: any = router.currentRoute.params;
      app = {
        language: query.language || params.language || '',
        targetSegment: query.targetSegment || params.targetSegment || '',
        subSegment: query.subSegment || params.subSegment || '',
        profile: query.profile || params.profile || '',
        line: query.line || params.line || '',
        areaOfService: query.areaOfService || params.areaOfService || '',
        products: query.products || params.products || ''
      };
    }
    router.replace({
      name: routeName,
      params: {
        targetSegment: app.targetSegment
      },
      query: {
        subSegment: app.subSegment,
        language: app.language,
        profile: app.profile,
        line: app.line,
        areaOfService: app.areaOfService,
        products: app.products
      }
    });
  }

  public static getTotalPrice(sessions: any) {
    let price = 0;
    _.forEach(sessions, (session: any) => {
      const status = ProductEngineUtils.getPrice(session);
      if (status && _.get(status, 'price.valid') && !status.price.needsManualPricing) {
        price += _.get(status, 'price.totalPrice');
      }
    });
    return price;
  }

  public static getDeclarationFacts() {
    return _.get(store.state.app.config.shiftMappings, Util.DECLARATION_FACT_IDS, []);
  }

  public static getValueWFallback(
    preferredValue,
    fallbackValue
  ) {
    return !_.isEmpty(preferredValue) ? preferredValue : fallbackValue;
  }

  public static initLivechat(livechat: any) {
    if (livechat?.url) {
      const win = (window as any);
      win.__lc = win.__lc || {};
      win.__lc.license = +livechat.license;
      const lc = document.createElement('script');
      if (lc) {
        lc.type = 'application/javascript';
        lc.async = false;
        lc.src = livechat.url;
        lc.onerror = (e: any) => {
          SentryService.logException({type: 'LIVECHAT', error: e});
        };
        const s = document.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
          s.parentNode.insertBefore(lc, s);
        }
      }
    }
  }

  public static init8x8Livechat(livechat: any) {
    if (livechat) {
      const se = document.createElement('script');
      se.type = 'text/javascript';
      se.async = true;
      se.src = conf.livechat8x8.domain + conf.livechat8x8.path + '/CHAT/common/js/chat.js';

      const os = document.getElementsByTagName('script')[0];
      if (os) {
        os.parentNode!.insertBefore(se, os);
      }

      return (conf.livechat8x8 as any).uuid as any || '';
    }
  }

  public static initGoogleMaps(config: any) {
    if (config && config.key && config.src && _.get(window, 'google.maps') === undefined) {
      const gm = document.createElement('script');
      if (gm) {
        gm.type = 'application/javascript';
        gm.async = false;
        gm.src = config.src + '&key=' + config.key;
        gm.onerror = (e: any) => {
          SentryService.logException({type: 'GOOGLE_MAPS', error: e});
        };
        const s = document.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
          s.parentNode.insertBefore(gm, s);
        }
      }
    }
  }

  public static initBPoint() {
    return WorkflowService.runWorkflowSync('generate-bpoint-authkey', {}, ['authKey']);
  }

  public static async initMIGS(invoiceId) {
    return WorkflowService.runWorkflowSync(
        'get-migs-payment-url',
        {
            invoiceId: `"${invoiceId}"`,
            redirectUrl: `${window.location.protocol}//${window.location.host}/payment_callback.html`
        },
        ['url'],
    );
  }

  public static async initWindcave(invoiceId) {
    const result = await this.loadWindcaveScript();
    return WorkflowService.runWorkflowSync(
        'create-windcave-session',
        {
            invoiceId: `"${invoiceId}"`,
            redirectUrl: `${window.location.protocol}//${window.location.host}/payment_callback.html`,
            userEmail: store.getters['quotation/getFactValueByFactID'](Util.APPLICANT_EMAIL_ID) || '',
            businessName: store.getters['quotation/getFactValueByFactID'](Util.POLICY_HOLDER_BUSINESS_NAME) || ''
        },
        ['sessionId', 'hppLink', 'seamlessHppLink'],
    );
  }

  public static loadWindcaveScript() {
    return new Promise((res, rej) => {
      if (_.get(window, 'WindcavePayments') === undefined) {
        const ew = document.createElement('script');
        if (ew != null) {
          ew.type = 'application/javascript';
          ew.async = false;
          ew.src = 'https://sec.windcave.com/js/windcavepayments-seamless-v1.js';
          const s = document.getElementsByTagName('script')[0];
          if (s && s.parentNode) {
            s.parentNode.insertBefore(ew, s);
          }
          ew.onload = () => {
            res();
          };
        } else {
          return rej();
        }
      } else {
        return res();
      }
    });
  }

  public static initEWay() {
    return new Promise((res, rej) => {
      if (_.get(window, 'eWay') === undefined) {
        const ew = document.createElement('script');
        if (ew != null) {
          ew.type = 'application/javascript';
          ew.async = false;
          ew.src = 'https://secure.ewaypayments.com/scripts/eWAY.min.js';
          const s = document.getElementsByTagName('script')[0];
          if (s && s.parentNode) {
            s.parentNode.insertBefore(ew, s);
          }
          ew.onload = () => {
            res();
          };
        } else {
          rej();
        }
      } else {
        res();
      }
    });
  }


  public static generateUid(prefix: string, suffix: string) {
    let uid = prefix ? (prefix.split('#').pop() + '@') : '';
    uid += suffix.split('#').pop();
    return uid.replace(/\./g, '_').replace(/:/g, '-'); // : and . are internally used by vee-validate
  }

  public static getTimeStampForPardot() {
    const timeStamp = new Date();
    return (moment(timeStamp).format(Util.PARDOT_TIMESTAMP_FORMAT));
  }

  public static getValueWithPardotTimestamp(value: any) {
    return this.getTimeStampForPardot() + '|' + value;
  }

  public static getSameHeight(products: any[]) {
    if (_.get(products, 'length') > 1) {
      const nameHeights: any = [];
      const premiumHeights: any = [];
      const benefitHeights: any = [];
      const imgHeights: any = [];
      products.forEach((product) => {
        const productName = product.querySelector('.product-name');
        const premium = product.querySelector('.premium');
        const benefit = product.querySelector('.benefits');
        const img = product.querySelector('.img');
        if (productName) {
          nameHeights.push(productName.offsetHeight);
        }
        if (premium) {
          premiumHeights.push(premium.offsetHeight);
        }
        if (benefit) {
          benefitHeights.push(benefit.offsetHeight);
        }
        if (img) {
          imgHeights.push(img.offsetHeight);
        }
      });
      products.forEach((product) => {
        const productName = product.querySelector('.product-name');
        const premium = product.querySelector('.premium');
        const benefit = product.querySelector('.benefits');
        const img = product.querySelector('.img');
        if (productName) {
          productName.style.height = `${_.max(nameHeights)}px`;
        }
        if (premium) {
          premium.style.height = `${_.max(premiumHeights)}px`;
        }
        if (benefit) {
          benefit.style.height = `${_.max(benefitHeights)}px`;
        }
        if (img) {
          img.style.height = `${_.max(imgHeights)}px`;
        }
      });
    }
  }

  public static isURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  public static extractEmailFromHtmlString(html: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  }

  public static goHome(router: any, app: any, completed: boolean = false) {
    let toUrl = _.get(app, completed ? 'catalogueUrl' : 'homeUrl', null);
    if (sessionStorage.subSegment) {
      toUrl += '&subSegment=' + sessionStorage.subSegment;
    }
    if (!toUrl) {
      router.push({ name: 'landing', params: { skipConfirm: 'true' } });
    }
    if (this.isURL(toUrl)) {
      window.location.href = toUrl;
    } else {
      router.push({ name: toUrl, params: { skipConfirm: 'true' } });
    }
  }

  public static removeLoader() {
    const loader = document.getElementById('main-loader');
    if (loader) {
      loader.classList.add('loader-fade-out');
      setTimeout(() => {
        loader.parentNode!.removeChild(loader);
      }, 1000);
    }
  }

  public static async markdownFormatted(text: string) {
    return text;
  }

  public static hasAutoFill(fact: any) {
    const integrations = _.get(store.state.app.config.shiftMappings, 'FACT_INTEGRATIONS', {});
    return _.some(integrations, (integration) => _.some(integration, (integrationConfig) => fact.id.includes(integrationConfig.field)));
  }

  public static childIFramesLoaded(el, timeout = 2000) {
    return new Promise((res, rej) => {
      const iFrames = el.querySelectorAll(`iframe`) as HTMLIFrameElement[];
      const loaded: HTMLIFrameElement[] = [];
      const isLoaded = () => loaded.length === iFrames.length;

      iFrames.forEach((iframeEl) => {
        iframeEl.onload! = () => {
          loaded.push(iframeEl);
          if (isLoaded()) {
            return res();
          }
        };
      });

      setTimeout(() => {
        if (!isLoaded()) {
          return rej('Timeout loading iFrames');
        }
      }, timeout);
    });

  }

  public static gaLogPageView(
    self,
    path?: string,
    page?: string
  ) {
    GAUtil.logGAPageView(self, path, page);
  }

  public static gaLogEcommerceEvent(
    self, action: string, label: string, value: {[key: string]: any; }
  ) {
    GAUtil.logGAEvent(self, action, 'E-commerce', label,  value);
  }

  public static gaLogCatalogueEvent(
    self,
    action: string,
    label: string,
    value?: string
  ) {
    GAUtil.logGAEvent(self, action, 'Catalogue', label, value);
  }
  public static gaLogCustomEvent(
    self,
    action: string,
    label: string,
    value?: any
  ) {
    GAUtil.logGAEvent(self, action, 'Custom-Event', label, value);
  }

  public static gaLogStepperEvent(
    self,
    action: string,
    stepperLocation: string
  ) {
    GAUtil.logGAEvent(self, action, 'Stepper', stepperLocation);
  }

  public static gaLogPaymentEvent(
    self,
    action: string,
    type: string,
    result: string
  ) {
    if (!type || !result) {
      return;
    }
    GAUtil.logGAEvent(self, action, 'Payment', this.GA_PAYMENT_STATUSES[type][result]);
  }

  public static gaLogSurveyEvent(
    self,
    question: string,
    answer: string,
  ) {
    GAUtil.logGAEvent(self, 'answer', 'Survey', question, answer);
  }
}
